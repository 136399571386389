import React from 'react'

import { Button, Buttons, Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'
import { ButtonsExample } from '../../examples/react/ButtonsExample'
import { ButtonsWrapExample } from '../../examples/react/ButtonsWrapExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Buttons"
    components={[{ component: Buttons }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Paragraph>
        <Code>Buttons</Code>-komponentilla ryhmitellyt painikkeet asettuvat
        pienillä näytöillä allekkain ja isoilla näytöillä vierekkäin pitäen
        etäisyyden toisiinsa.
      </Paragraph>
      <Playground>
        <>
          <Buttons>
            <Button variant="outline">Teksti</Button>
            <Button>Teksti</Button>
          </Buttons>
          <Buttons align="center">
            <Button variant="outline">Teksti</Button>
            <Button>Teksti</Button>
          </Buttons>
          <Buttons
            align="right"
            noMargin
          >
            <Button variant="outline">Teksti</Button>
            <Button>Teksti</Button>
          </Buttons>
        </>
      </Playground>
      <Playground format="html">
        <>
          <div className="buttons">
            <ButtonHTMLExample variant="outline">Teksti</ButtonHTMLExample>
            <ButtonHTMLExample>Teksti</ButtonHTMLExample>
          </div>
          <div className="buttons buttons-center">
            <ButtonHTMLExample variant="outline">Teksti</ButtonHTMLExample>
            <ButtonHTMLExample>Teksti</ButtonHTMLExample>
          </div>
          <div className="buttons buttons-right mb-0">
            <ButtonHTMLExample variant="outline">Teksti</ButtonHTMLExample>
            <ButtonHTMLExample>Teksti</ButtonHTMLExample>
          </div>
        </>
      </Playground>
    </Section>
    <Section title="Rivittyminen">
      <Paragraph>
        Perustilassa <Code>Buttons</Code> pitää painikkeet yhdellä rivillä.
        Yleisesti ottaen painikkeita tulisikin olla maksimissaan kolme ja
        painikkeiden tekstit pitää lyhyinä, jotta painikkeet mahtuisivat yhdelle
        riville kapeammillakin näytöillä. Tarvittaessa painikkeiden rivittyminen
        voidaan mahdollistaa <Code>wrap</Code>-propilla.
      </Paragraph>
      <Playground example={ButtonsExample} />
      <Playground example={ButtonsWrapExample} />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Komponentille ei ole erityisiä saavutettavuushuomioita.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
