import React from 'react'
import { Button, Buttons } from '@te-digi/styleguide'

const ButtonsExample = () => (
  <Buttons>
    <Button variant="outline">Poistu</Button>
    <Button variant="outline">Keskeytä käsittely</Button>
    <Button color="danger">Peru tapaaminen</Button>
    <Button color="danger">Poista tapaaminen</Button>
    <Button color="secondary">Merkitse käsitellyksi</Button>
  </Buttons>
)

export { ButtonsExample }
